import * as types from '../mutation_types'

// state
const state = {
  users: null,
  isFetching: false,
  details: null,
  stats: [],
}

// getters
const getters = {
  users: (state) => state.users,
  stats: (state) => state.stats,
  isFetching: (state) => state.isFetching,
}

// mutations
const mutations = {
  [types.FETCH_USERS_SUCCESS](state, users) {
    state.users = users
  },
  [types.FETCH_USERS_STATS_SUCCESS](_, stats) {
    state.stats = stats
  },
  [types.FETCH_USERS_FAILURE](_, e) {
    console.log(e)
  },
}

// actions
const actions = {
  async fetchUsers({ commit }, data) {
    try {
      const {
        data: { users },
      } = await this.$axios.get('/api/user/get-all', {
        params: data,
      })

      commit(types.FETCH_USERS_SUCCESS, users)
    } catch (e) {
      commit(types.FETCH_USERS_FAILURE, e)
    }
  },
  async fetchUsersStats({ commit }) {
    try {
      const {
        data: { stats },
      } = await this.$axios.get('/api/user/stats')

      commit(types.FETCH_USERS_STATS_SUCCESS, stats)
    } catch (e) {
      commit(types.FETCH_USERS_STATS_FAILURE, e)
    }
  },
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
