import * as types from '../mutation_types'

// state
const state = {
  celles: null,
  orders: null,
  isFetching: false,
}

// getters
const getters = {
  celles: (state) => state.celles,
  orders: (state) => state.orders,
  isFetching: (state) => state.isFetching,
}

// mutations
const mutations = {
  [types.FETCH_CELLES_SUCCESS](state, { celles }) {
    state.celles = celles
  },

  [types.FETCH_CELLES_ORDER_SUCCESS](state, { orders }) {
    state.orders = orders
  },

  [types.FETCH_CELLES_FAILURE](_, e) {
    console.log(e)
  },
  [types.FETCH_CELLES_ORDER_FAILURE](_, e) {
    console.log(e)
  },
}

// actions
const actions = {
  async fetchCelles({ commit }, data) {
    try {
      const {
        data: { celles },
      } = await this.$axios.get('/api/zobo/get-all', {
        params: data,
      })

      commit(types.FETCH_CELLES_SUCCESS, { celles })
    } catch (e) {
      commit(types.FETCH_CELLES_FAILURE, e)
    }
  },

  async orders({ commit }, data) {
    try {
      const {
        data: { orders },
      } = await this.$axios.get('/api/zobo/orders', {
        params: data,
      })

      commit(types.FETCH_CELLES_ORDER_SUCCESS, { orders })
    } catch (e) {
      commit(types.FETCH_CELLES_ORDER_FAILURE, e)
    }
  },

  async deliverOrder(_, data) {
    try {
      const res = await this.$axios.post(
        `/api/zobo/orders/deliver/${data.order_id}`,
        {
          from: data.from,
        },
      )
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
