import * as types from '../mutation_types'

const ENDPOINT = '/api/ads'
// state
const state = {
  adverts: null,
  advert: {},
  isFetching: false,
  sections: null,
}

// getters
const getters = {
  adverts: (state) => state.adverts,
  advert: (state) => state.advert,
  isFetching: (state) => state.isFetching,
  sections: (state) => state.sections,
}

// mutations
const mutations = {
  [types.FETCH_ADVERTS_SUCCESS](state, adverts) {
    state.adverts = adverts
  },
  [types.FETCH_ADVERT_SUCCESS](state, advert) {
    state.advert = advert
  },
  [types.FETCH_ADVERT_SECTION_SUCCESS](state, sections) {
    state.sections = sections
  },

  [types.ADVERT_DELETED](state, id) {
    state.adverts.results = state.adverts.results.filter(
      (advert) => advert._id !== id,
    )
  },

  [types.ADVERT_SECTION_DELETED](state, id) {
    state.sections.results = state.sections.results.filter(
      (section) => section._id !== id,
    )
  },
}

// actions
const actions = {
  async create({ commit }, data) {
    try {
      await this.$axios.post(`${ENDPOINT}/create`, data)
      commit(types.CREATE_ADVERT_SUCCESS)
    } catch (error) {
      return Promise.reject(error)
      //   commit(types.CREATE_CATEGORY_FAILURE, error)
    }
  },
  async createSection(_, data) {
    try {
      await this.$axios.post(`${ENDPOINT}/sections/create`, data)
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async update(_, data) {
    try {
      await this.$axios.patch(`${ENDPOINT}/update/${data.id}`, data.advert)
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
      //   commit(types.CREATE_CATEGORY_FAILURE, error)
    }
  },
  async updateSection(_, data) {
    try {
      await this.$axios.patch(`${ENDPOINT}/sections/update/${data._id}`, data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async destroy({ commit }, id) {
    try {
      await this.$axios.delete(`${ENDPOINT}/${id}`)
      commit(types.ADVERT_DELETED, id)
      return Promise.resolve({})
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async destroySection({ commit }, id) {
    try {
      await this.$axios.delete(`${ENDPOINT}/sections/${id}`)
      commit(types.ADVERT_SECTION_DELETED, id)
      return Promise.resolve({})
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async get({ commit }, data) {
    try {
      let {
        data: { adverts },
      } = await this.$axios.get(`${ENDPOINT}/adverts`, {
        params: data,
      })
      commit(types.FETCH_ADVERTS_SUCCESS, adverts)
    } catch (e) {
      commit(types.FETCH_ADVERT_FAILURE, e)
    }
  },

  async getSections({ commit }, data) {
    try {
      let {
        data: { sections },
      } = await this.$axios.get(`${ENDPOINT}/sections`, {
        params: data,
      })
      commit(types.FETCH_ADVERT_SECTION_SUCCESS, sections)
      return Promise.resolve(sections)
    } catch (e) {
      commit(types.FETCH_ADVERT_SECTION_FAILURE, e)
    }
  },

  async getSection({ commit }, id) {
    try {
      let {
        data: { section },
      } = await this.$axios.get(`${ENDPOINT}/sections/${id}`)
      return Promise.resolve(section)
    } catch (e) {
      commit(types.FETCH_ADVERT_FAILURE, e)
    }
  },

  async getAdvert({ commit }, id) {
    try {
      let {
        data: { advert },
      } = await this.$axios.get(`${ENDPOINT}/advert/${id}`)
      return Promise.resolve(advert)
    } catch (e) {
      commit(types.FETCH_ADVERT_FAILURE, e)
    }
  },
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
