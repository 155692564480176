import * as types from '../mutation_types'

const ENDPOINT = '/api/project'
// state
const state = {
  projects: null,
  project: {},
  payouts: null,
  supports: null,
  isFetching: false,
}

// getters
const getters = {
  projects: (state) => state.projects,
  payouts: (state) => state.payouts,
  supports: (state) => state.supports,
  isFetching: (state) => state.isFetching,
}

// mutations
const mutations = {
  [types.FETCH_PROJECTS_SUCCESS](state, projects) {
    state.projects = projects
  },
  [types.FETCH_PAYOUT_SUCCESS](state, payouts) {
    state.payouts = payouts
  },
  [types.FETCH_SUPPORT_SUCCESS](state, supports) {
    state.supports = supports
  },
  [types.PAYOUT_PAID](state, id) {
    state.payouts.results = state.payouts.results.map((payout) => {
      if (payout._id == id) {
        payout.status = 'Paid'
      }
      return payout
    })
  },
  [types.SUPPORT_PAID_PAID](state, id) {
    state.supports.results = state.supports.results.map((support) => {
      if (support._id == id) {
        support.payment_status = 'Paid'
      }
      return support
    })
  },
}

// actions
const actions = {
  async destroy({ commit }, id) {
    try {
      await this.$axios.delete(`${ENDPOINT}/${id}`)
      commit(types.PROJECTS_DELETED, id)
      return Promise.resolve({})
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getAll({ commit }, data) {
    try {
      let {
        data: { projects },
      } = await this.$axios.get(`${ENDPOINT}/get-all`, {
        params: { ...data, isAdmin: true },
      })
      commit(types.FETCH_PROJECTS_SUCCESS, projects)
    } catch (e) {
      // commit(types.FETCH_PROJECT_FAILURE, e)
    }
  },

  async getAllPayout({ commit }, data) {
    try {
      let {
        data: { payouts },
      } = await this.$axios.get(`${ENDPOINT}/get-all-payout`, {
        params: { ...data, isAdmin: true },
      })
      commit(types.FETCH_PAYOUT_SUCCESS, payouts)
    } catch (e) {
      // commit(types.FETCH_PROJECT_FAILURE, e)
    }
  },

  async getAllSupport({ commit }, data) {
    try {
      let {
        data: { supports },
      } = await this.$axios.get(`${ENDPOINT}/get-all-support/${data.id}`, {
        params: { ...data, isAdmin: true },
      })
      commit(types.FETCH_SUPPORT_SUCCESS, supports)
    } catch (e) {
      // commit(types.FETCH_PROJECT_FAILURE, e)
    }
  },

  async updateStatus(_, data) {
    try {
      await this.$axios.post(`${ENDPOINT}/approval`, data)
      return Promise.resolve({})
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async markAsPaid({ commit }, id) {
    try {
      await this.$axios.patch(`${ENDPOINT}/payout/mark-as-paid/${id}`)
      commit(types.PAYOUT_PAID, id)
      return Promise.resolve({})
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async markAsPaidSupport({ commit }, id) {
    try {
      await this.$axios.patch(`${ENDPOINT}/support/mark-as-paid/${id}`)
      commit(types.SUPPORT_PAID, id)
      return Promise.resolve({})
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async updateApproval(_, data) {
    try {
      const { id } = data
      delete data.id
      await this.$axios.patch(`${ENDPOINT}/update/status/${id}`, data)
      return Promise.resolve({})
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async updateFeatured(_, data) {
    const { id } = data
    delete data.id
    try {
      await this.$axios.patch(`${ENDPOINT}/update/priority/${id}`, data)
      return Promise.resolve({})
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async updateVStatus(_, data) {
    const { id } = data
    delete data.id
    try {
      await this.$axios.patch(
        `${ENDPOINT}/update/visibility-status/${id}`,
        data,
      )
      return Promise.resolve({})
    } catch (e) {
      return Promise.reject(e)
    }
  },
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
