import Swal from 'sweetalert2/dist/sweetalert2.js'

const appUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://cellepay.me'
    : 'http://localhost:3000'

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})

export const mixin = {
  methods: {
    parseError(err) {
      if (err.response.status === 422) {
        Swal.fire({
          title: 'Validation Error',
          text: Object.values(err.response.data.errors)[0],
          confirmButtonColor: '#e50913',
          icon: 'error',
        })
      } else if (err.response.status === 401) {
        Swal.fire({
          title: 'Oops, server Error!',
          text: err.response.data.msg,
          confirmButtonColor: '#e50913',
          icon: 'error',
        })
      } else {
        Swal.fire({
          title: 'Oops,  something went wrong!',
          text: 'Internal error',
          confirmButtonColor: '#e50913',
          icon: 'error',
        })
      }
    },
    parseSuccess(msg) {
      Swal.fire({
        text: msg,
        confirmButtonColor: '#e50913',
        icon: 'success',
      })
    },
    parseErrorMsg(msg) {
      Swal.fire({
        title: 'Oops,something went wrong',
        text: msg,
        confirmButtonColor: '#e50913',
        icon: 'error',
      })
    },
    toast(type, msg) {
      Toast.fire({
        icon: type,
        title: msg,
      })
    },
    confirm(title) {
      return Swal.fire({
        title: title,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Proceed',
        denyButtonText: `Never Mind`,
      })
    },
    generateLink(type, slug) {
      switch (type) {
        case 'celle':
          return `${appUrl}/celle/${slug}`
        case 'project':
          return `${appUrl}/project/${slug}`
        case 'user':
          return `${appUrl}/${slug}`
        default:
          break
      }
    },
  },
}
