import axios from 'axios'
import store from '../store'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import router from './../router'

// Request interceptor
axios.defaults.baseURL =
  process.env.NODE_ENV === 'production'
    ? 'https://api.cellepay.me/'
    : 'http://localhost:3001'

axios.interceptors.request.use((request) => {
  const token = store.getters['token']
  if (token) {
    request.headers.common.Authorization = `Bearer ${token}`
  }
  return request
})

// Response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response

    if (status === 401) {
      Swal.fire({
        icon: 'warning',
        title: 'token_expired_alert_title',
        text: 'token_expired_alert_text',
        reverseButtons: true,
        confirmButtonText: 'ok',
        cancelButtonText: 'cancel',
      }).then(() => {
        store.commit('destroyUser')
        router.push({ name: 'Login' })
      })
    }

    if (status >= 500) {
      //   serverError(error.response)
      alert()
    }

    return Promise.reject(error)
  },
)

// let serverErrorModalShown = false
// async function serverError(response) {
//   if (serverErrorModalShown) {
//     return
//   }

//   if ((response.headers['content-type'] || '').includes('text/html')) {
//     const iframe = document.createElement('iframe')

//     if (response.data instanceof Blob) {
//       iframe.srcdoc = await response.data.text()
//     } else {
//       iframe.srcdoc = response.data
//     }

//     Swal.fire({
//       html: iframe.outerHTML,
//       showConfirmButton: false,
//       customClass: { container: 'server-error-modal' },
//       didDestroy: () => {
//         serverErrorModalShown = false
//       },
//       grow: 'fullscreen',
//       padding: 0,
//     })
//     serverErrorModalShown = true
//   } else {
//     Swal.fire({
//       icon: 'error',
//       title: 'error_alert_title',
//       text: 'error_alert_text',
//       reverseButtons: true,
//       confirmButtonText: 'ok',
//       cancelButtonText: 'cancel',
//     })
//   }
// }

export const axiosInstance = axios

// axios.create()
