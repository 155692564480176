import * as types from '../mutation_types'

const ENDPOINT = '/api/vendor'
// state
const state = {
  vendors: null,
  vendor: {},
  isFetching: false,
}

// getters
const getters = {
  vendors: (state) => state.vendors,
  vendor: (state) => state.vendor,
  isFetching: (state) => state.isFetching,
}

// mutations
const mutations = {
  [types.FETCH_VENDORS_SUCCESS](state, vendors) {
    state.vendors = vendors
  },
}

// actions
const actions = {
  async destroy({ commit }, id) {
    try {
      await this.$axios.delete(`${ENDPOINT}/${id}`)
      commit(types.CATEGORY_DELETED, id)
      return Promise.resolve({})
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getAll({ commit }, data) {
    try {
      let {
        data: { vendors },
      } = await this.$axios.get(`${ENDPOINT}/get-all`, {
        params: data,
      })
      commit(types.FETCH_VENDORS_SUCCESS, vendors)
    } catch (e) {
      //   commit(types.FETCH_VENDORS_FAILURE, e)
    }
  },
  async updateStatus(_, data) {
    try {
      await this.$axios.post(`${ENDPOINT}/approval`, data)
      return Promise.resolve({})
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async getById({ commit }, id) {
    try {
      let {
        data: { category },
      } = await this.$axios.get(`${ENDPOINT}/${id}`)
      commit(types.FETCH_CATEGORY_SUCCESS, category)
    } catch (e) {
      console.log(e)
    }
  },
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
