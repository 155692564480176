// user/index.js

export const FETCH_USERS = 'FETCH_USERS'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'
export const FETCH_USERS_STATS_SUCCESS = 'FETCH_USERS_STATS_SUCCESS'
export const FETCH_USERS_STATS_FAILURE = 'FETCH_USERS_STATS_FAILURE'

// vendor/index.js
export const FETCH_VENDORS = 'FETCH_VENDORS'
export const FETCH_VENDORS_SUCCESS = 'FETCH_VENDORS_SUCCESS'
export const FETCH_VENDORS_FAILURE = 'FETCH_VENDORS_FAILURE'

// cellepay/index.js
export const FETCH_CELLE = 'FETCH_CELLE'
export const FETCH_CELLE_SUCCESS = 'FETCH_CELLE_SUCCESS'
export const FETCH_CELLE_FAILURE = 'FETCH_CELLE_FAILURE'

// category/index.js
export const FETCH_CATEGORY = 'FETCH_CATEGORY'
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS'
export const FETCH_CATEGORY_FAILURE = 'FETCH_CATEGORY_FAILURE'
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE'
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS'

export const CREATE_CATEGORY = 'CREATE_CATEGORY'
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS'
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE'
export const CATEGORY_DELETED = 'CATEGORY_DELETED'

// product/index.js
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS'
export const FETCH_SHIPPINGS_SUCCESS = 'FETCH_SHIPPINGS_SUCCESS'
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS'
export const PRODUCT_DELETED = 'PRODUCT_DELETED'

// cellepay/index.js
export const FETCH_CELLES_SUCCESS = 'FETCH_CELLES_SUCCESS'
export const FETCH_CELLES_FAILURE = 'FETCH_CELLES_FAILURE'
export const FETCH_CELLES_ORDER_SUCCESS = 'FETCH_CELLES_ORDER_SUCCESS'
export const FETCH_CELLES_ORDER_FAILURE = 'FETCH_CELLES_ORDER_FAILURE'

// project/index.js
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS'
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE'
export const PROJECTS_DELETED = 'PROJECTS_DELETED'

export const FETCH_TRANSACTION_SUCCESS = 'FETCH_TRANSACTION_SUCCESS'
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS'

// advert/index.js
export const FETCH_ADVERT_SUCCESS = 'FETCH_ADVERT_SUCCESS'
export const FETCH_ADVERTS_SUCCESS = 'FETCH_ADVERTS_SUCCESS'
export const FETCH_ADVERT_FAILURE = 'FETCH_ADVERT_FAILURE'
export const CREATE_ADVERT_SUCCESS = 'CREATE_ADVERT_SUCCESS'
export const ADVERT_DELETED = 'ADVERT_DELETED'

export const FETCH_ADVERT_SECTION_SUCCESS = 'FETCH_ADVERT_SECTION_SUCCESS'
export const FETCH_ADVERT_SECTION_FAILURE = 'FETCH_ADVERT_SECTION_FAILURE'
export const ADVERT_SECTION_DELETED = 'ADVERT_SECTION_DELETED'

// coupon/index.js
export const FETCH_COUPONS_SUCCESS = 'FETCH_COUPONS_SUCCESS'
export const FETCH_COUPON_SUCCESS = 'FETCH_COUPON_SUCCESS'
export const FETCH_COUPON_FAILURE = 'FETCH_COUPON_FAILURE'
export const COUPON_DELETED = 'COUPON_DELETED'

// payout
export const FETCH_PAYOUT_SUCCESS = 'FETCH_PAYOUT_SUCCESS'
export const PAYOUT_PAID = 'PAYOUT_PAID'

// payout
export const FETCH_MESSAGE_SUCCESS = 'FETCH_MESSAGE_SUCCESS'
export const FETCH_LOADING = 'FETCH_LOADING'
export const MESSAGE_DELETED = 'MESSAGE_DELETED'
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS'

// payout
export const FETCH_SUPPORT_SUCCESS = 'FETCH_SUPPORT_SUCCESS'
export const SUPPORT_PAID = 'SUPPORT_PAID'

// form
export const FETCH_FORM_SUCCESS = 'FETCH_FORM_SUCCESS'
export const FETCH_FORM_FAILURE = 'FETCH_FORM_FAILURE'
