import * as types from '../mutation_types'

// state
const state = {
  coupons: null,
  isFetching: false,
  coupon: {},
}

// getters
const getters = {
  coupons: (state) => state.coupons,
  coupon: (state) => state.coupon,
  isFetching: (state) => state.isFetching,
}

// mutations
const mutations = {
  [types.FETCH_COUPONS_SUCCESS](state, { coupons }) {
    state.coupons = coupons
  },
  [types.FETCH_COUPON_SUCCESS](state, coupon) {
    state.coupon = coupon
  },
  [types.COUPON_DELETED](state, id) {
    state.coupons.results = state.coupons.results.filter(
      (coupon) => coupon._id !== id,
    )
  },

  [types.FETCH_CELLES_FAILURE](_, e) {
    console.log(e)
  },
}

// actions
const actions = {
  async fetchCoupons({ commit }, data) {
    try {
      const {
        data: { coupons },
      } = await this.$axios.get('/api/coupon/', {
        params: data,
      })

      commit(types.FETCH_COUPONS_SUCCESS, { coupons })
    } catch (e) {
      commit(types.FETCH_COUPON_FAILURE, e)
    }
  },
  async create(_, data) {
    try {
      let res = await this.$axios.post('/api/coupon/create', data)
      Promise.resolve(res)
    } catch (err) {
      Promise.reject(err)
    }
  },
  async update(_, data) {
    try {
      await this.$axios.patch(`/api/coupon/update/${data.id}`, data.coupon)
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async destroy({ commit }, id) {
    try {
      await this.$axios.delete(`/api/coupon/${id}`)
      commit(types.COUPON_DELETED, id)
      return Promise.resolve({})
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getById({ commit }, id) {
    try {
      let {
        data: { coupon },
      } = await this.$axios.get(`/api/coupon/${id}`)
      coupon.visibility_status =
        coupon.visibility_status == 'Live' ? true : false
      commit(types.FETCH_COUPON_SUCCESS, coupon)
    } catch (e) {
      commit(types.FETCH_COUPON_FAILURE, e)
    }
  },
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
