import { createStore } from 'vuex'
import user from './user'
import category from './category'
import vendor from './vendor'
import product from './product'
import project from './project'
import cellepay from './cellepay'
import advert from './advert'
import coupon from './coupon'
import site from './site'

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    user: null || JSON.parse(localStorage.getItem('user')),
    token: localStorage.getItem('token'),
    transactions: [],
    notifications: [],
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    updateUser(state, payload) {
      state.user = payload.user
    },
    updateToken(state, payload) {
      state.token = payload.token
    },
    destroyUser(state) {
      state.token = null
      state.user = null
    },
    transactionSuccess(state, payload) {
      state.transactions = payload
    },
  },
  actions: {
    setUser({ commit }, payload) {
      commit('updateUser', payload)
      commit('updateToken', payload)

      localStorage.setItem('token', payload.token)
      localStorage.setItem('user', JSON.stringify(payload.user))
    },
    destroyUser({ commit }) {
      commit('destroyUser')

      localStorage.removeItem('token')
      localStorage.removeItem('user')
    },
    async getTransactions({ commit }, data) {
      try {
        let {
          data: { transactions },
        } = await this.$axios.get(`/api/auth/transactions`, {
          params: data,
        })
        commit('transactionSuccess', transactions)
      } catch (e) {
        //   commit(types.FETCH_VENDORS_FAILURE, e)
      }
    },
  },
  getters: {
    user: (state) => state.user,
    token: (state) => state.token,
    transactions: (state) => state.transactions,
  },
  modules: {
    userModule: user,
    categoryModule: category,
    vendorModule: vendor,
    productModule: product,
    projectModule: project,
    cellepayModule: cellepay,
    advertModule: advert,
    couponModule: coupon,
    siteModule: site,
  },
})
