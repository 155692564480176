export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },

  {
    component: 'CNavGroup',
    name: 'User',
    to: '/base',
    icon: 'cil-user',
    items: [
      {
        component: 'CNavItem',
        name: 'User Management',
        to: '/users',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Vendor',
    to: '/buttons',
    icon: 'cil-cursor',
    items: [
      {
        component: 'CNavItem',
        name: 'Vendor Management',
        to: '/vendor',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Store',
    to: '/forms',
    icon: 'cil-notes',
    items: [
      {
        component: 'CNavItem',
        name: 'Product Management',
        to: '/products',
      },
      {
        component: 'CNavItem',
        name: 'Category Management',
        to: '/categories',
      },
      {
        component: 'CNavItem',
        name: 'Orders Management',
        to: '/orders',
      },
      {
        component: 'CNavItem',
        name: 'Shipping',
        to: '/shipping',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'CellePay',
    to: '/icons',
    icon: 'cil-gift',
    items: [
      {
        component: 'CNavItem',
        name: 'Cellepay Management',
        to: '/cellepay',
        // badge: {
        //   color: 'info',
        //   text: 'NEW',
        // },
      },
      {
        component: 'CNavItem',
        name: 'Cellepay Orders',
        to: '/cellepay/orders',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Coupon',
    to: '/icons',
    icon: 'cil-gift',
    items: [
      {
        component: 'CNavItem',
        name: 'Coupon Management',
        to: '/coupons',
        badge: {
          color: 'info',
          text: 'NEW',
        },
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Project',
    to: '/icons',
    icon: 'cil-star',
    items: [
      {
        component: 'CNavItem',
        name: 'project Management',
        to: '/project',
      },
      {
        component: 'CNavItem',
        name: 'Payout Management',
        to: '/payouts',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Site Management',
    to: '/notifications',
    icon: 'cil-settings',
    items: [
      {
        component: 'CNavItem',
        name: 'Adverts',
        to: '/adverts',
      },
      {
        component: 'CNavItem',
        name: 'Advert Sections',
        to: '/advert/sections',
      },
      {
        component: 'CNavItem',
        name: 'Messenger',
        to: '/messages',
      },
      // {
      //   component: 'CNavItem',
      //   name: 'Blog',
      //   to: '/notifications/badges',
      // },
      // {
      //   component: 'CNavItem',
      //   name: 'Settings',
      //   to: '/notifications/modals',
      // },
      {
        component: 'CNavItem',
        name: 'Forms',
        to: '/forms',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Transactions',
    to: '/transactions',
    icon: 'cil-transfer',
  },

  {
    component: 'CNavGroup',
    name: 'FAQ',
    to: '/pages',
    icon: 'cil-star',
    items: [
      {
        component: 'CNavItem',
        name: 'All FAQ',
        to: '/pages/login',
      },
      {
        component: 'CNavItem',
        name: 'Category',
        to: '/pages/register',
      },
    ],
  },

  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
]
