import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuex from 'vuex'

import CoreuiVue from '@coreui/vue'
import CKEditor from '@ckeditor/ckeditor5-vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'
import Spinner from '@/components/Spinner'
import Loader from '@/components/Loader'
import moment from 'moment'

import { mixin } from './mixins'
import { axiosInstance } from './plugins/axios'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(CKEditor)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)
app.component('Spinner', Spinner)
app.component('Loader', Loader)
app.mixin(mixin)
app.config.globalProperties.$axios = axiosInstance
Vuex.Store.prototype.$axios = axiosInstance

app.config.globalProperties.$filters = {
  formatMoney(value, currency = 'NGN') {
    return value.toLocaleString(currency == 'NGN' ? 'en-NG' : 'en-US', {
      style: 'currency',
      currency: currency,
      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })
  },

  formatDate(value) {
    return moment(value).format('DD MMMM,YYYY')
  },

  formatDateTime(value) {
    return moment(value).format('DD MMMM,YYYY, HH:MM')
  },

  formatDate2(value) {
    // "yyyy-MM-dd".
    return moment(value).format('yyyy-MM-DD')
  },
}

app.mount('#app')
