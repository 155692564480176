import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

// import auth from '../middleware/admin'

import DefaultLayout from '@/layouts/DefaultLayout'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    meta: {
      middleware: true,
    },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/users',
        name: 'Users',
        component: () => import('@/views/users/Index.vue'),
      },
      {
        path: '/category',
        name: 'Category',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/categories',
        children: [
          {
            path: '/categories',
            name: 'Categories',
            component: () => import('@/views/category/Index.vue'),
          },
          {
            path: '/category/create',
            name: 'Create Category',
            component: () => import('@/views/category/Create.vue'),
          },
          {
            path: '/category/:id/edit',
            name: 'Edit Category',
            component: () => import('@/views/category/Edit.vue'),
          },
        ],
      },
      {
        path: '/advert',
        name: 'Advert',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/adverts',
        children: [
          {
            path: '/adverts',
            name: 'Adverts',
            component: () => import('@/views/advert/Index.vue'),
          },
          {
            path: '/advert/create',
            name: 'Create Advert',
            component: () => import('@/views/advert/Create.vue'),
          },
          {
            path: '/advert/:id/edit',
            name: 'Edit Advert',
            component: () => import('@/views/advert/Edit.vue'),
          },
          {
            path: '/advert/sections',
            name: 'Advert Sections',
            component: () => import('@/views/advert/sections/Index.vue'),
          },
          {
            path: '/advert/section/create',
            name: 'Create Advert Section',
            component: () => import('@/views/advert/sections/Create.vue'),
          },
          {
            path: '/advert/section/:id/edit',
            name: 'Edit Advert Section',
            component: () => import('@/views/advert/sections/Edit.vue'),
          },
        ],
      },
      {
        path: '/message',
        name: 'Message',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/messages',
        children: [
          {
            path: '/messages',
            name: 'Messages',
            component: () => import('@/views/messenger/Index.vue'),
          },
          {
            path: '/messages/create',
            name: 'Create Message',
            component: () => import('@/views/messenger/Create.vue'),
          },
        ],
      },
      {
        path: '/vendor',
        name: 'Vendor',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/vendors',
        children: [
          {
            path: '/vendors',
            name: 'Vendors',
            component: () => import('@/views/vendor/Index.vue'),
          },
          {
            path: '/vendor/create',
            name: 'Create Vendor',
            component: () => import('@/views/vendor/Create.vue'),
          },
          {
            path: '/vendor/:id/edit',
            name: 'Edit',
            component: () => import('@/views/vendor/Edit.vue'),
          },
        ],
      },

      {
        path: '/product',
        name: 'Product',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/products',
        children: [
          {
            path: '/products',
            name: 'Products',
            component: () => import('@/views/products/Index.vue'),
          },
          {
            path: '/orders',
            name: 'Orders',
            component: () => import('@/views/products/Orders.vue'),
          },
        ],
      },

      {
        path: '/cellepay',
        name: 'Cellepay',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/cellepays',
        children: [
          {
            path: '/cellepays',
            name: 'Cellepays',
            component: () => import('@/views/cellepay/Index.vue'),
          },
          {
            path: '/cellepay/orders',
            name: 'Cellepay Orders',
            component: () => import('@/views/cellepay/orders/Index.vue'),
          },
        ],
      },
      {
        path: '/coupons',
        name: 'Coupons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/coupons',
        children: [
          {
            path: '/coupons',
            name: 'Coupons Management',
            component: () => import('@/views/coupon/Index.vue'),
          },
          {
            path: '/coupons/create',
            name: 'Create Coupon',
            component: () => import('@/views/coupon/Create.vue'),
          },
          {
            path: '/coupons/:id/edit',
            name: 'Edit Coupon',
            component: () => import('@/views/coupon/Edit.vue'),
          },
        ],
      },
      {
        path: '/project',
        name: 'Project',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/projects',
        children: [
          {
            path: '/projects',
            name: 'Projects',
            component: () => import('@/views/project/Index.vue'),
          },
          {
            path: '/:id/support',
            name: 'Supports',
            component: () => import('@/views/project/Support.vue'),
          },
          {
            path: '/payouts',
            name: 'Payouts',
            component: () => import('@/views/project/Payout.vue'),
          },
        ],
      },
      {
        path: '/shipping',
        name: 'Shipping',
        component: () => import('@/views/shippings/Index.vue'),
      },
      // UI Designs Route

      {
        path: '/theme',
        name: 'Theme',
        redirect: '/theme/typography',
      },
      {
        path: '/theme/colors',
        name: 'Colors',
        component: () => import('@/views/theme/Colors.vue'),
      },
      {
        path: '/theme/typography',
        name: 'Typography',
        component: () => import('@/views/theme/Typography.vue'),
      },
      {
        path: '/base',
        name: 'Base',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/base/breadcrumbs',
        children: [
          {
            path: '/base/accordion',
            name: 'Accordion',
            component: () => import('@/views/base/Accordion.vue'),
          },
          {
            path: '/base/breadcrumbs',
            name: 'Breadcrumbs',
            component: () => import('@/views/base/Breadcrumbs.vue'),
          },
          {
            path: '/base/cards',
            name: 'Cards',
            component: () => import('@/views/base/Cards.vue'),
          },
          {
            path: '/base/carousels',
            name: 'Carousels',
            component: () => import('@/views/base/Carousels.vue'),
          },
          {
            path: '/base/collapses',
            name: 'Collapses',
            component: () => import('@/views/base/Collapses.vue'),
          },
          {
            path: '/base/list-groups',
            name: 'List Groups',
            component: () => import('@/views/base/ListGroups.vue'),
          },
          {
            path: '/base/navs',
            name: 'Navs',
            component: () => import('@/views/base/Navs.vue'),
          },
          {
            path: '/base/paginations',
            name: 'Paginations',
            component: () => import('@/views/base/Paginations.vue'),
          },
          {
            path: '/base/placeholders',
            name: 'Placeholders',
            component: () => import('@/views/base/Placeholders.vue'),
          },
          {
            path: '/base/popovers',
            name: 'Popovers',
            component: () => import('@/views/base/Popovers.vue'),
          },
          {
            path: '/base/progress',
            name: 'Progress',
            component: () => import('@/views/base/Progress.vue'),
          },
          {
            path: '/base/spinners',
            name: 'Spinners',
            component: () => import('@/views/base/Spinners.vue'),
          },
          {
            path: '/base/tables',
            name: 'Tables',
            component: () => import('@/views/base/Tables.vue'),
          },
          {
            path: '/base/tooltips',
            name: 'Tooltips',
            component: () => import('@/views/base/Tooltips.vue'),
          },
        ],
      },
      {
        path: '/buttons',
        name: 'Buttons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/buttons/standard-buttons',
        children: [
          {
            path: '/buttons/standard-buttons',
            name: 'Buttons',
            component: () => import('@/views/buttons/Buttons.vue'),
          },
          {
            path: '/buttons/dropdowns',
            name: 'Dropdowns',
            component: () => import('@/views/buttons/Dropdowns.vue'),
          },
          {
            path: '/buttons/button-groups',
            name: 'Button Groups',
            component: () => import('@/views/buttons/ButtonGroups.vue'),
          },
        ],
      },
      {
        path: '/transactions',
        name: 'Transactions',
        component: () => import('@/views/transaction/Index.vue'),
      },
      {
        path: '/forms',
        name: 'Forms',
        component: () => import('@/views/forms/Index.vue'),
        // children: [
        //   {
        //     path: '/forms',
        //     name: 'Form Control',
        //     component: () => import('@/views/forms/FormControl.vue'),
        //   },
        //   {
        //     path: '/forms/select',
        //     name: 'Select',
        //     component: () => import('@/views/forms/Select.vue'),
        //   },
        //   {
        //     path: '/forms/checks-radios',
        //     name: 'Checks & Radios',
        //     component: () => import('@/views/forms/ChecksRadios.vue'),
        //   },
        //   {
        //     path: '/forms/range',
        //     name: 'Range',
        //     component: () => import('@/views/forms/Range.vue'),
        //   },
        //   {
        //     path: '/forms/input-group',
        //     name: 'Input Group',
        //     component: () => import('@/views/forms/InputGroup.vue'),
        //   },
        //   {
        //     path: '/forms/floating-labels',
        //     name: 'Floating Labels',
        //     component: () => import('@/views/forms/FloatingLabels.vue'),
        //   },
        //   {
        //     path: '/forms/layout',
        //     name: 'Layout',
        //     component: () => import('@/views/forms/Layout.vue'),
        //   },
        //   {
        //     path: '/forms/validation',
        //     name: 'Validation',
        //     component: () => import('@/views/forms/Validation.vue'),
        //   },
        // ],
      },
      {
        path: '/charts',
        name: 'Charts',
        component: () => import('@/views/charts/Charts.vue'),
      },
      {
        path: '/icons',
        name: 'Icons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/icons/coreui-icons',
        children: [
          {
            path: '/icons/coreui-icons',
            name: 'CoreUI Icons',
            component: () => import('@/views/icons/CoreUIIcons.vue'),
          },
          {
            path: '/icons/brands',
            name: 'Brands',
            component: () => import('@/views/icons/Brands.vue'),
          },
          {
            path: '/icons/flags',
            name: 'Flags',
            component: () => import('@/views/icons/Flags.vue'),
          },
        ],
      },
      {
        path: '/notifications',
        name: 'Notifications',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/notifications/alerts',
        children: [
          {
            path: '/notifications/alerts',
            name: 'Alerts',
            component: () => import('@/views/notifications/Alerts.vue'),
          },
          {
            path: '/notifications/badges',
            name: 'Badges',
            component: () => import('@/views/notifications/Badges.vue'),
          },
          {
            path: '/notifications/modals',
            name: 'Modals',
            component: () => import('@/views/notifications/Modals.vue'),
          },
        ],
      },
      {
        path: '/widgets',
        name: 'Widgets',
        component: () => import('@/views/widgets/Widgets.vue'),
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
    ],
  },
]

//  createWebHashHistory(process.env.BASE_URL),
const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, _, next) => {
  console.log(_)
  if (
    to.name !== 'Login' &&
    to.meta.middleware &&
    (!store.getters['user'] || store.getters['user'].role !== 'Admin')
  )
    next({ name: 'Login' })
  else next()
})

export default router
