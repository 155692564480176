import * as types from '../mutation_types'

const ENDPOINT = '/api/products'
const ENDPOINTORDER = '/api/order'
// state
const state = {
  products: null,
  product: {},
  isFetching: false,
  shippings: null,
  orders: [],
}

// getters
const getters = {
  products: (state) => state.products,
  shippings: (state) => state.shippings,
  product: (state) => state.product,
  orders: (state) => state.orders,
  isFetching: (state) => state.isFetching,
}

// mutations
const mutations = {
  [types.FETCH_PRODUCTS_SUCCESS](state, products) {
    state.products = products
  },
  [types.FETCH_SHIPPINGS_SUCCESS](state, shippings) {
    state.shippings = shippings
  },
  [types.FETCH_ORDERS_SUCCESS](state, orders) {
    state.orders = orders
  },
}

// actions
const actions = {
  async destroy({ commit }, id) {
    try {
      await this.$axios.delete(`${ENDPOINT}/${id}`)
      commit(types.PRODUCT_DELETED, id)
      return Promise.resolve({})
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getAll({ commit }, data) {
    try {
      let {
        data: { products },
      } = await this.$axios.get(`${ENDPOINT}/get-all`, {
        params: { ...data, isAdmin: true },
      })
      commit(types.FETCH_PRODUCTS_SUCCESS, products)
    } catch (e) {
      //   commit(types.FETCH_VENDORS_FAILURE, e)
    }
  },
  async getAllOrders({ commit }, data) {
    try {
      let {
        data: { orders },
      } = await this.$axios.get(`${ENDPOINTORDER}/get-all`, {
        params: { ...data },
      })
      commit(types.FETCH_ORDERS_SUCCESS, orders)
    } catch (e) {
      //   commit(types.FETCH_VENDORS_FAILURE, e)
    }
  },

  async getShippings({ commit }, data) {
    try {
      let {
        data: { shippings },
      } = await this.$axios.get(`${ENDPOINT}/get-all-shippings`, {
        params: { ...data, isAdmin: true },
      })
      commit(types.FETCH_SHIPPINGS_SUCCESS, shippings)
    } catch (e) {
      //   commit(types.FETCH_VENDORS_FAILURE, e)
    }
  },

  async updateStatus(_, data) {
    try {
      await this.$axios.post(`${ENDPOINT}/approval`, data)
      return Promise.resolve({})
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async getById({ commit }, id) {
    try {
      let {
        data: { category },
      } = await this.$axios.get(`${ENDPOINT}/${id}`)
      commit(types.FETCH_PRODUCT_SUCCESS, category)
    } catch (e) {
      console.log(e)
    }
  },
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
