<template>
  <router-view />
</template>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';

:root {
  --cui-sidebar-bg: #121212;
}
</style>
