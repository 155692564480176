import * as types from '../mutation_types'

const ENDPOINT_MSG = '/api/messenger'
const ENDPOINT_FORM = '/api/form'
// state

const state = {
  messages: null,
  isFetching: false,
  forms: null,
}

// getters
const getters = {
  forms: (state) => state.forms,
  messages: (state) => state.messages,
  isFetching: (state) => state.isFetching,
}

// mutations
const mutations = {
  [types.FETCH_MESSAGE_SUCCESS](state, messages) {
    state.messages = messages
  },
  [types.FETCH_LOADING](state, val) {
    state.isFetching = val
  },
  [types.MESSAGE_DELETED](state, id) {
    state.messages.results = state.messages.results.filter(
      (message) => message._id !== id,
    )
  },
  [types.FETCH_FORM_SUCCESS](state, forms) {
    state.forms = forms
  },
}

// actions
const actions = {
  async createMessage(_, data) {
    try {
      await this.$axios.post(`${ENDPOINT_MSG}`, data)
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
      //   commit(types.CREATE_CATEGORY_FAILURE, error)
    }
  },

  async destroyMsg({ commit }, id) {
    try {
      await this.$axios.delete(`${ENDPOINT_MSG}/${id}`)
      commit(types.MESSAGE_DELETED, id)
      return Promise.resolve({})
    } catch (error) {
      console.log(error)
      return Promise.reject(error)
    }
  },
  async getMessages({ commit }) {
    try {
      commit(types.FETCH_LOADING, true)
      let {
        data: { messages },
      } = await this.$axios.get(`${ENDPOINT_MSG}`)
      commit(types.FETCH_MESSAGE_SUCCESS, messages)
      commit(types.FETCH_LOADING, false)

      return Promise.resolve(messages)
    } catch (e) {
      commit(types.FETCH_LOADING, false)
      commit(types.FETCH_MESSAGE_FAILURE, e)
    }
  },

  async getForms({ commit }, filter) {
    try {
      commit(types.FETCH_LOADING, true)
      let {
        data: { forms },
      } = await this.$axios.get(`${ENDPOINT_FORM}/get-all`, {
        params: filter,
      })
      commit(types.FETCH_FORM_SUCCESS, forms)
      commit(types.FETCH_LOADING, false)

      return Promise.resolve(forms)
    } catch (e) {
      commit(types.FETCH_LOADING, false)
      commit(types.FETCH_FORM_FAILURE, e)
    }
  },
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
