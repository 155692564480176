import * as types from '../mutation_types'

const ENDPOINT = '/api/category'
// state
const state = {
  categories: [],
  category: {},
  isFetching: false,
}

// getters
const getters = {
  categories: (state) => state.categories,
  category: (state) => state.category,
  isFetching: (state) => state.isFetching,
}

// mutations
const mutations = {
  [types.FETCH_CATEGORIES_SUCCESS](state, categories) {
    state.categories = categories
  },
  [types.FETCH_CATEGORY_SUCCESS](state, category) {
    state.category = category
  },
  [types.CATEGORY_DELETED](state, id) {
    state.categories = state.categories.filter(
      (category) => category._id !== id,
    )
  },
}

// actions
const actions = {
  async create(_, data) {
    try {
      await this.$axios.post(`${ENDPOINT}/create`, data)
      // commit(types.CREATE_CATEGORY_SUCCESS)
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
      //   commit(types.CREATE_CATEGORY_FAILURE, error)
    }
  },
  async update({ commit }, data) {
    try {
      await this.$axios.patch(`${ENDPOINT}/${data.id}`, data.category)
      commit(types.CREATE_CATEGORY_SUCCESS)
    } catch (error) {
      return Promise.reject(error)
      //   commit(types.CREATE_CATEGORY_FAILURE, error)
    }
  },
  async destroy({ commit }, id) {
    try {
      await this.$axios.delete(`${ENDPOINT}/${id}`)
      commit(types.CATEGORY_DELETED, id)
      return Promise.resolve({})
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async get({ commit }, data) {
    try {
      let {
        data: { categories },
      } = await this.$axios.get(`${ENDPOINT}`, {
        params: data,
      })
      commit(types.FETCH_CATEGORIES_SUCCESS, categories)
    } catch (e) {
      commit(types.FETCH_CATEGORY_FAILURE, e)
    }
  },

  async getById({ commit }, id) {
    try {
      let {
        data: { category },
      } = await this.$axios.get(`${ENDPOINT}/${id}`)
      commit(types.FETCH_CATEGORY_SUCCESS, category)
    } catch (e) {
      commit(types.FETCH_CATEGORY_FAILURE, e)
    }
  },

  async upload(_, data) {
    try {
      const res = await this.$axios.post(`${ENDPOINT}/upload`, data)
      return Promise.resolve(res.data)
    } catch (error) {
      return Promise.reject(error)
      //   commit(types.CREATE_CATEGORY_FAILURE, error)
    }
  },
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
